"use client";
const { useState, useEffect } = require("react");

import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Alert,
  MenuItem,
  Select,
  Stack,
  Container,
  FormControl,
  InputLabel,
  TextField,
  IconButton,
} from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { LoadingButton } from "@mui/lab";
import { usePathname, useRouter } from "next/navigation";
import { sleep } from "@/lib/utils";

export const RegistrationPopupModal = ({ children }) => {
  return (
    <div>
      {children}
      {/* Modal below here */}
      <ModalSignUp />
    </div>
  );
};

// Modal Popup Styles
// *
// *
// *
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "360px", sm: "70%" },
  height: { xs: "73vh", sm: "80vh" },
  overflow: "auto",
  bgcolor: "background.paper",
  borderRadius: 1,
  outline: "none",
  boxShadow: 24,
  p: { xs: 3, sm: 6 },
};

// Modal Popup Here
// *
// *
// *
function ModalSignUp() {
  const router = useRouter();
  const pathname = usePathname();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [title, setTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");

  useEffect(() => {
    // Exclude 3 page (Meaning: don't show popup in these pages.)
    const excludedPages = ["/proof-of-funds", "/see-clients", "/invest", "/home/about"];

    (async () => {
      await sleep(1000);
      setOpen(!excludedPages.includes(pathname));
    })();
  }, [pathname]);

  const handleChange = (newValue, info) => {
    console.log("Telephone:", newValue);
    console.log("Telephone info:", info);

    const countryDialingCode = info.countryCallingCode; // e.g: +1 USA
    const enteredNumber = info.nationalNumber;
    const fullTelephoneNumberWithDialingCode = info.numberValue;
    setPhoneNumber(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataBody = {
      email,
      firstName,
      lastName,
      phoneNumber,
    };
    // Run a check on values
    if (!email || !firstName || !lastName || !phoneNumber) {
      return alert("Please complete the fields.");
    }
    console.log(dataBody);
    try {
      const response = await fetch("/api/clients", {
        method: "POST",
        body: JSON.stringify(dataBody),
        headers: {
          "Content-type": "application/json",
        },
      });

      const data = await response.json();
      console.log("Response from API:", data);
      if (data.error) {
        // User already exist.
        console.log("User already exist.");
        if (data.errorCode === 11000) {
          return router.push("/invest");
        }
        return alert("Something went wrong!");
      }
      // Change route.
      data && router.push("/invest");
      handleClose();
    } catch (e) {
      console.log("Error posting client information:", e);
    }
  };
  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.7)", // Adjust the opacity (0.5 in this case)
        },
      }}
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          {/* <IconButton
            sx={{
              position: "absolute",
              top: { xs: -32, sm: -40, md: -55 },
              right: { xs: -16, sm: -25 },
              fontWeight: 600,
              fontSize: { xs: 14, sm: 17 },
            }}
            color="error"
            onClick={handleClose}
          >
            ❌
          </IconButton> */}
          <Typography
            id="modal-modal-title"
            component="h2"
            color="textPrimary"
            sx={{ color: "#252525de", textAlign: "center", my: 2, fontSize: { xs: 22, sm: 30 } }}
            fontWeight={600}
          >
            REGISTER YOUR INTEREST
          </Typography>
          <Alert severity="info">Non-UAE Residents are eligible for complimentary round-trip flights to Dubai</Alert>
          <Typography sx={{ mt: 4, textAlign: "center" }}>
            <Typography sx={{ display: "inline" }} color="error">
              *
            </Typography>
            All fields are compulsory
          </Typography>
          <Container maxWidth="xs">
            <Box component="form" sx={{ my: 2 }} onSubmit={handleSubmit}>
              <Stack direction="column" gap={1.3}>
                {/* SELECT COMPONENT FOR TITLE */}
                <FormControl>
                  <InputLabel sx={{ color: "black" }}>Title</InputLabel>
                  <Select
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ color: "black" }}
                  >
                    <MenuItem aria-label="None" value="" />
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </Select>
                </FormControl>
                {/* FIRST NAME */}
                <TextField
                  required
                  label="First name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField required label="Last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                <TextField required label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                <MuiTelInput forceCallingCode placeholder="Phone" value={phoneNumber} onChange={handleChange} />
                <LoadingButton type="submit" sx={{ p: 1.5 }} variant="contained">
                  Enquire Now
                </LoadingButton>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
    </Modal>
  );
}
