"use client";

const { createContext, useState, useEffect } = require("react");

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [mode, setMode] = useState("");
  const [direction, setDirection] = useState("");
  const [layout, setLayout] = useState("");
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (mode == "light") {
        document.querySelector("body").classList.add("light");
      } else {
        if (document.querySelector("body").classList.contains("light")) {
          document.querySelector("body").classList.remove("light");
        }
      }
    }
  }, [mode]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (direction == "rtl") {
        document.querySelector("body").classList.add("dir-rtl");
      } else {
        if (document.querySelector("body").classList.contains("dir-rtl")) {
          document.querySelector("body").classList.remove("dir-rtl");
        }
      }
    }
  }, [direction]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (layout == "box") {
        document.getElementById("smooth-content").classList.add("box-layout");
      } else {
        if (
          document.getElementById("smooth-content") &&
          document
            .getElementById("smooth-content")
            .classList.contains("box-layout")
        ) {
          document
            .getElementById("smooth-content")
            .classList.remove("box-layout");
        }
      }
    }
  }, [layout]);
  return (
    <AppContext.Provider
      value={{ mode, setMode, direction, setDirection, layout, setLayout }}
    >
      {children}
    </AppContext.Provider>
  );
};
